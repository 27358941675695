
import React from "react";

import {  db } from "../firebase";

import ReactHtmlParser from 'react-html-parser';
 
import post from "../Components/PostBlog";

class Blog extends React.Component {

  constructor(props) {
    
    super(props) 
    this.state = {
       posts:[],
       post: {}
    }
  }



  componentDidMount() {
    

    const id = this.props.match.params.id; 

    this.getPost(id);
    this.getPosts()
  }


  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      // logic to use movieID and resynchronize any data.
      this.getPost(this.props.match.params.id)
      this.getPosts()
      window.scrollTo(0, 0);
    }
  }
 getPost(id) {
     db.collection("blog").doc(id).get().then((snapshot) => {
      var data = snapshot.data();
      this.setState({article:data})
  }).catch((e) => console.log(e))  }


addS(newItem) {
    this.setState({ posts: [...this.state.posts, newItem] })
}
  
async getPosts() {
      this.setState({posts:[]});
      await db.collection("blog").where("id", "!=", this.props.match.params.id).limit(4).get()
      .then(res => {
          res.forEach(doc => {

              let newItem = doc.data();
              let id = doc.id;
              newItem.id = id;
              this.addS(newItem)

          });
      })
      .catch(err => { console.error(err) });
  } 

  renderPosts =  () => {

    var data = this.state.posts;
    return(data.map(item => post(item)) )
   
  }



 

  render() {
    return (
      
      <main className="mt-4">
      <div className="row mb-3 mb-xl-0 bg "
          style={{
          height:'300px',
          backgroundImage:  `url(${this.state.article?.img})`, 
          backgroundPosition: 'center left', backgroundSize: 'cover', backgroundOpacity:0.3}}
          >
          <div className=" container" >
          <div className="container">
            <h1 className="lh-base mb-0 mt-5 text-white">{this.state.article?.title}</h1>
          </div>
        </div>
        </div>
      <div className="container ">
        <div className="row mt-4">
        <div className="col-md-8 blog-main ">
       

          <div className="blog-post">
            <h2 className="blog-post-title"></h2>
            <p className="blog-post-meta">{this.state.article?.fecha}</p>

            { ReactHtmlParser(this.state.article?.content) }       
              
          </div>

     
       
         
        </div>{/* /.blog-main */}

        <aside className="col-md-4 blog-sidebar">
          <div className="p-3 mb-3 bg-light rounded">
            <h4 className="font-italic">Nosotros</h4>
            <p className="mb-0">Theraphya es una plataforma de terapia online la cual te ayuda con todo tipo de recursos para cambiar tu vida a mejor</p>
          </div>

      

          <div className="p-3">
            <h4 className="font-italic">Encuentranos en las redes</h4>
            <ol className="list-unstyled">

              <li><a href="https://instagram.com/theraphya.app" target="__blank"><i className="fa-brands fa-instagram"/> Instagram</a></li>
              <li><a href="https://tiktok.com/@theraphya.app" target="__blank"><i className="fa-brands fa-tiktok"/> Tiktok</a></li>


            </ol>
          </div>
        </aside>{/* /.blog-sidebar */}
        </div>
      </div>{/* /.row */}

      <div className="container">
      <div className="row mb-2 mt-3">
        <h3 className="mb-3">Articulos relacionados</h3>
        {this.renderPosts()}
      </div>
      </div>

    </main>

      
    )
  }

 }


  export default Blog;