import firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
import 'firebase/storage';     // for storage
import 'firebase/firestore';   // for cloud firestore

import toast from 'react-hot-toast';
import Swal from 'sweetalert2'


const firebaseConfig = {
  apiKey: "AIzaSyAOJ-ROVuAajdh586V9VB1F4xUUnE1MCI4",
  authDomain: "theraphya-com.firebaseapp.com",
  projectId: "theraphya-com",
  storageBucket: "theraphya-com.appspot.com",
  messagingSenderId: "57998716544",
  appId: "1:57998716544:web:861a0c3558b1c4f547c065"
};

let app;


if (!firebase.apps.length) {
   app = firebase.initializeApp(firebaseConfig);
} else {
  app = firebase.app();

}
const fire = app;
const auth = app.auth();
const db = app.firestore();
const st = app.storage();


const googleProvider = new firebase.auth.GoogleAuthProvider();

const signInWithGoogle = async () => {
  try {
    const res = await auth.signInWithPopup(googleProvider);
    const user = res.user;
    const query = await db
      .collection("users")
      .where("uid", "==", user.email)
      .get();
    if (query.docs.length === 0) {
      await db.collection("users").doc(user.email).set({
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
        therapist: "david@gmail.com",
      });
    }
  } catch (err) {
    toast.error(err.message)
  }
};


const registerInWithGoogle = async (answers,name) => {
  try {
    const res = await auth.signInWithPopup(googleProvider);
    const user = res.user;
    const query = await db
      .collection("users")
      .where("email", "==", user.email)
      .get();
    if (query.docs.length === 0) {

      var myArray = ['carmunoz92@gmail.com', 'ezespiazzi@gmail.com', 'juancruzferrero@gmail.com']; 
      var th = myArray[(Math.random() * myArray.length) | 0]
  

      await db.collection("users").doc(user.email).set({
        uid: user.uid,
        name: name || user.displayName,
        authProvider: "google",
        email: user.email,
        answers:answers,
        therapist: th,
      });

      await db.collection("therapists").doc(th).collection("patients").add({
        name: user.displayName,
        email: user.email,
        answers:answers,
      });
    }
  } catch (err) {
    toast.error(err.message)
  }
};


const signInWithEmailAndPassword = async (email, password) => {

  
  if(email !== "" && password !== "") {
  try {
    await auth.signInWithEmailAndPassword(email, password);
    const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      });
      Toast.fire({
        icon: "success",
        title: "Sesión iniciada"
      });
   
  } catch (err) {
    toast.error("Error en formulario comprueba los campos")
  }}
  else {
    toast.error("Error en formulario comprueba los campos")
  }
};

const registerWithEmailAndPassword = async ( email, password,name,answers) => {
  
  var date = Date.now();


  
  if(email !== "" && password !== "" ) {
    if(password.length < 7) {
      toast.error("La contraseña debe ser de al menos 6 caracteres")
    } else {
  
    try {
    const res = await auth.createUserWithEmailAndPassword(email, password);
    const user = res.user;

    var myArray = ['carmunoz92@gmail.com', 'ezespiazzi@gmail.com', 'juancruzferrero@gmail.com']; 
    var th = myArray[(Math.random() * myArray.length) | 0]

    await db.collection("users").doc(email).set({
      uid: user.uid,
      email,
      creationDate:date,
      lastStatus: date,
      name:name || "",
      description: " ",
      answers:answers,
      therapist:th,
    });

    await db.collection("therapists").doc(th).collection("patients").add({
      name: name,
      email: user.email,
      answers:answers,
    });

  } catch (err) {
    if(err.message === "auth/invalid-password") {
      toast.error("La contraseña debe ser de al menos 6 caracteres")
    } else {
    toast.error("Error en formulario comprueba los campos") 
    }
  } }
  } else {
    toast.error("Error en formulario comprueba los campos")
  }

};

const sendPasswordResetEmail = async (email) => {
  if(email !== "") {
  try {
    await auth.sendPasswordResetEmail(email);
    toast.success("Se te ha envidado al email una link de reseteo de contraseña")
  } catch (err) {
    toast.error("Error en formulario comprueba los campos")
  }}
  else{ 
    toast.error("Error en formulario comprueba los campos")
  }
};


const logout = () => {
  const Toast = Swal.mixin({
    toast: true,
    position: "top-end",
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.onmouseenter = Swal.stopTimer;
      toast.onmouseleave = Swal.resumeTimer;
    }
  });

  Toast.fire({
    icon: "success",
    title: "Sesión cerrada"
  });
  auth.signOut();

  setTimeout(() => {
    window.location.href = "/"    
  }, 1000);

};



export {
  auth,
  db,
  st,
  fire,
  signInWithGoogle,
  signInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordResetEmail,
  logout,
  registerInWithGoogle
};
