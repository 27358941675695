import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'


import { uploadFromBlobAsync } from './storage'

function SelectImage({onDone, ComponentRender}) {
  const [isLoading, setIsLoading] = useState(false)
  const onDrop = useCallback(async (acceptedFiles) => {
    const file = acceptedFiles?.[0]

    if (!file) {
      return
    }

    setIsLoading(true)

    try {
      const url = await uploadFromBlobAsync({
        blobUrl: URL.createObjectURL(file),
        name: `${file.name}_${Date.now()}`,
      })
        onDone(url)

        } catch (e) {
      setIsLoading(false)
      return
    }

    setIsLoading(false)
  }, [])

  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  return (
    <span {...getRootProps()}>
    <input
      className='input' {...getInputProps()}
    /> 
    <ComponentRender/>

   
</span>
  )
}

export default SelectImage;