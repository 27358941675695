
import React from "react";

import {  db } from "../firebase";

import InstaFeed from "../Components/InstaFeed";

import post from "../Components/PostBlog";

class Article extends React.Component {

  constructor(props) {    
    super(props) 
    this.state = {
       posts:[]
    }
  }
  componentDidMount() {
    this.getPosts()
    const query = new URLSearchParams(this.props.location.search);
    const sp = query.get('successpay')
        if(sp == 'true') {
          this.sucessPayment()
        }
  }



addS(newItem) {
    this.setState({ posts: [...this.state.posts, newItem] })
}


async getPosts() {
      this.setState({posts:[]});
      await db.collection("blog").get()
      .then(res => {
          res.forEach(doc => {

              let newItem = doc.data();
              let id = doc.id;
              newItem.id = id;
              this.addS(newItem)

          });
      })
      .catch(err => { console.error(err) });
  } 

  renderPosts =  () => {
    var data = this.state.posts;
    return(data.map(item => post(item)) )
  }



 

  render() {
    return (
      <main className="container">
      <div className="p-4 p-md-5 mb-4 rounded text-body-emphasis bg-body-secondary">
        <div className="col-lg-6 px-0">
          <h1 className="display-4 fst-italic mt-5">La terapia comienza en la educación</h1>
          <p className="lead my-3">Encuentra los mejores artículos de psicología moderna y educación mental  </p>
          <p className="lead mb-0"><a className="text-body-emphasis fw-bold">Tu camino empieza aquí</a></p>
        </div>
      </div>

      <div className="row mb-2 mt-3 mb-2">

     
        {this.renderPosts()}
      </div>

        <InstaFeed/>
       {/*{this.renderSesions() }*/}

        </main>


    )
  }

 }


  export default Article;