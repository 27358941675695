import { Link } from "react-router-dom/cjs/react-router-dom.min";

export default function post(item) {

    return (
         <Link to={`/article/${item.id}`} className="col-md-6 col-12 ">
            <div className="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow h-md-250 position-relative fixHeight">
              <div className="col p-4 d-flex flex-column position-static">
                <strong className="badge pill d-inline-block mb-2 text-success-emphasis">{item.type}</strong>
                <h3 className="mb-0">{item.title}</h3>
                <div className="mb-1 text-body-secondary">{item.subtitle}</div>
                <a  className="icon-link gap-1 icon-link-hover stretched-link">
                  Seguir leyendo
  
                </a>
              </div>
              <div className="col-auto d-none d-lg-block col-md-4" 
                style={{
                  backgroundImage:  `url(${item.img})`, 
                  backgroundPosition: 'center left', backgroundSize: 'cover', backgroundOpacity:0.3}}
              >
                {/* <img src={} className="bd-placeholder-img" width="200" height="250"  role="img" /> */}
              </div>
            </div>
          </Link>
          )
  }
  