import React from "react";

import {  auth, db, logout } from "../firebase";
import SelectImage from "../Components/SelectImage";

import {  Modal, ModalBody, Button, ModalHeader } from 'react-bootstrap';

import NotFound from "../assets/notfound.png"

import 'react-day-picker/dist/style.css';
import { format } from 'date-fns';


import toast from 'react-hot-toast';
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Chat from "./Chat";

class Settings extends React.Component {

  constructor(props) {
    super(props) 
    this.state = {
        transactions:[],
        name:"",
        description:"",
        retireMethod: "paypal",
        balance:0,
        retireAmount:0,
        retireAccount: null,
        patientsList:[],
    }

  } 
  
  componentDidMount() {
    
    this.getUser()
    this.getPatients()
    
    setTimeout(() => {
        this.getUser()
    }, 1000);
  }



  async getUser() {

    if(auth.currentUser) {
        await db.collection('therapists').doc(auth.currentUser?.email).onSnapshot((doc) => {
        const userData = doc.data();
        if (doc && doc.exists) {
          this.setState({isTherapist: true})
        
          this.setState(userData);
          this.getSesions() 
     
        
      }
      else {
          this.setState({isTherapist: false})
      }
    })
    } 
    else {
      this.setState({isTherapist: false})

  }
};

addS(newItem) {
  this.setState({ sesions: [...this.state.sesions, newItem] })
}

openSesion(item) {
  this.setState({modalSesion:true,sesionData:item})

}



addPacient(newItem) {
  this.setState({ patientsList: [...this.state.patientsList, newItem] })
}

async getSesions() {
    this.setState({sesions:[]});
    await db.collection("therapists").doc(auth.currentUser?.email).collection('sesions').get()
    .then(res => {
        res.forEach(doc => {

            let newItem = doc.data();
            this.addS(newItem)

        });
    })
    .catch(err => { console.error(err) });


} 

async getPatients() {
  this.setState({patientsList:[]});
  await db.collection("therapists").doc(auth.currentUser?.email).collection('patients').get()
  .then(res => {
      res.forEach(doc => {

          let newItem = doc.data();
          newItem.email = doc.id;
          this.addPacient(newItem)

      });
  })
  .catch(err => { console.error(err) });
}

openPatient(item) {
  this.setState({modalPatient:true})
  this.setState({patientData:item})
}

renderPatient(item)  {

  return(
          <div className="card   shadow m-2 " onClick={() => this.openPatient(item)}>
          <div className="d-flex   align-items-center">


            <div className=" col-9 p-2">
            <div className="d-flex">
            <div className="thumbnailCard algin-item-center ">
          
            <img className="rounded-circle" src="https://static.vecteezy.com/system/resources/thumbnails/009/734/564/small/default-avatar-profile-icon-of-social-media-user-vector.jpg" width={48} />
              
          </div>


            <div className="p-2">
            <h4 className="mb-0">{item?.name || item?.email}</h4>
            <a className="link">{item?.email}</a>
            </div>
            </div>
            </div>
         
            <div className=" col-2" >
        
            <Button variant="outline-primary" text="" className="" onClick={() => this.openChat(item)}>
            Chatear { item?.check == false ?  <label title="+1" className="badge pill bg-primary">+1</label> : null }
            </Button>

            </div>
           

            
            </div>         
        </div>
  )

}

renderSesion(item)  {

  return(
    <div className="card   shadow m-2 " onClick={() => this.openSesion(item)}>
    <div className="d-flex  align-items-center ">

      <div className="thumbnailCard m-2">
        <img className="rounded-circle" src={item.user?.img ||  "https://static.vecteezy.com/system/resources/thumbnails/009/734/564/small/default-avatar-profile-icon-of-social-media-user-vector.jpg"} width={48} />
        
      </div>
      <div className="">
      <h5 className=" mb-0 mt-1">Sesión con <b>{item.user?.name}</b> reservada </h5>
      <h5>{item.dayDisplay}</h5>
      </div>
      <div className="">
       <h4 className="badge bg-dark m-3">{item.hour}</h4>
        
      </div>
      <Button variant="outline-primary" text="" className="mx-4">
      Ver detalles sesión
      </Button>


      
      </div>         
  </div>
  )

}


renderSesions =  () => {

    var data = this.state.sesions;

    if(data?.length > 0) {
      return(data.map(item => this.renderSesion(item)) )
    } else {
      return(
        <>
        <div className="text-center justify-content-center">
          <img src={NotFound} className="img-fluid" width={"200px"}></img>
          <h3 className="text-center">No tienes ninguna sesión </h3>
        </div>
        </>
      )
    }
   
  }



renderPatients =  () => {

  var data = this.state.patientsList;


  if(data?.length > 0) {
    return(data.map(item => this.renderPatient(item)) )
  } else {
    return(
      <>
      <div className="text-center justify-content-center">
        <img src={NotFound} className="img-fluid" width={"200px"}></img>
        <h3 className="text-center">No tienes ningún paciente asignado </h3>
      </div>
      </>
    )
  }
 
}


  cancelarSesion() {

  }
 
  openChat(user) {

    this.setState({chatUser: user})

    const array = [auth.currentUser?.email,user?.email]
    array.sort();
    var id = array[0] + array[1];
    db.collection("therapists").doc(auth.currentUser?.email).collection("patients").doc(user?.email).set({check:true}, {merge:true})
    this.setState({openChat:true,chatId: id})


    this.getPatients()
  }

  renderModalSesion() {
    return(

      <Modal show={this.state.modalSesion == true} className="center" fullscreen={"sm-down"}  id="modalCentered"  size={"lg"} centered>
        <ModalHeader className="modal-header"  >
          <h5 className="modal-title">Sesion reservada</h5>
          <button className="btn-close" type="button" onClick={() => this.setState({modalSesion:false})} />
        </ModalHeader>
        <ModalBody className="">

      <div className="card card-body  shadow   mt-1">
      <div className="d-flex d-md-block d-lg-flex align-items-start 4 mt-1">
        
        <img className="rounded-circle" src="https://static.vecteezy.com/system/resources/thumbnails/009/734/564/small/default-avatar-profile-icon-of-social-media-user-vector.jpg" width={48} />
        <div className="pt-md-2 pt-lg-0 ps-3 ps-md-0 ps-lg-3 mt-0">
       
        <h5 className=" mb-2 ">{format(Date(this.state.sesionData?.day), "PP")}    </h5> 
        <span className="badge badge-pill bg-dark mb-0 ">{this.state.sesionData?.hour} </span> 
          <h4 className=" mb-2 ">Sesion con {this.state.sesionData?.user?.name} </h4> 
          Email contacto: <a className=" mb-0 ">{this.state.sesionData?.user?.email} </a> 
          { this.state.sesionData?.contactNumber ? <a className=" mb-0 ">Numero contacto {this.state.sesionData?.nameP} </a> : null}



          
          </div>
      </div>

    </div>
        

       <div className="btn btn-danger m-3   cursor" onClick={() => this.cancelarSesion(this.state.sesionData?.id)} >
            <label className=" cursor">Cancelar sesión</label>
        </div>
        <div className="btn btn-light cursor m-3" onClick={() => this.openChat(this.state.sesionData?.user)} >
            <label className="cursor"><i className="bi bi-chat fa-comments-dots"></i> Chatear con paciente</label>
        </div>
     
        </ModalBody>


        <Modal.Footer>

        <Button variant="secondary" onClick={() => this.setState({modalSesion: false,})}>
          Cerrar
        </Button>
      </Modal.Footer>
      </Modal>
      
    )
}


  async updateUser() {
    if(auth.currentUser) {
      await db.collection('therapists').doc(auth.currentUser.email).update(this.state).then(() => {
        toast.success("Perfil actualizado correctemente")
      })
    } else {
      toast.error("Error: Intentelo mas tarde y asegure que esta inciaido sesión debidamente")
    }
  }


  renderProfile(){
    if(!this.state.isTherapist) {
      return (
        <>
        	<section className="pb-0 ">
		    	<div className=""
		    	style={{
		    	height:'480px',
		    	backgroundImage: 'url(https://images.unsplash.com/photo-1551847677-dc82d764e1eb?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)', 
		    	backgroundPosition: 'center left', backgroundSize: 'cover', backgroundOpacity:0.2}}
		    	>
		    	<div className=" container" >
					<div className="container p-3">
						<h1 className="lh-base mb-0 mt-5 text-white">Hazte terapeuta</h1>
            <h4 className="text-white">Uneté a Theraphya como terapeuta</h4>

            <Button type="primary" className="mt-5">
              Solitica unirte a nuestro equipo de psicólogos
            </Button>
            <br/>
            <br/>
            <Link to="/login" className="btn btn-light" type="dark" color="light" >
              ¿Ya eres psicólogo en Theraphya? Entra en tu cuenta
            </Link>
					</div>
				</div>
				</div>

	  	</section>
    </>
      )
    } else {
      return (
        <>
       <main className="mt-4 mb-5">
      {/* Modal retire more  */}
      {this.renderModalSesion()}
      { this.state.openChat ?  
         <Chat
            therapist={true}
            show={this.state.openChat} 
            close={() => this.setState({openChat:false})} 
            chatUser={this.state.chatUser} 
            id={this.state.chatId} /> : 
          null}
  
      <div className="d-flex align-items-center d-lg-none tabbable mb-3">
              <ul className="nav nav-pills nav-tabs  small fw-normal card-body p-0 mx-1">
                 
                 <li className="nav-item py-1 " >
                     <a  href="#nav-setting-tab-1" className={this.state.tab !== "card" ?"nav-link d-flex mb-0 active ":"nav-link d-flex mb-0 "} data-bs-toggle="tab"> <span>Account </span></a>
                   </li>
                  
         
                   <li className="nav-item py-1 " >
                     <a  href="#nav-setting-tab-2" data-bs-toggle="tab" className={this.state.tab === "card" ?"nav-link d-flex mb-0 active text-light":"nav-link d-flex mb-0 text-dark"}> <span>Sesiones </span></a>
                   </li>



               
                   <li className="nav-item py-1 nav-pill-danger " onClick={() => logout()}>
                     <div className=" d-flex mb-0 text-white btn btn-danger btn-sm"  ><span>Cerrar sesión </span></div>
                   </li>
             
              </ul>
            </div>
      {/* Modal retire money end */}
      {/* Container START */}
      <div className="container mt-5">
        <div className="row mt-3">
        <div className="col-lg-3 col-md-3  p  ">
            {/* Advanced filter responsive toggler START */}
            {/* Divider */}
           
            {/* Advanced filter responsive toggler END */}
            <nav className="navbar navbar-light navbar-expand-lg mx-0 text-light">
              <div className="offcanvas offcanvas-start" tabIndex={-1} id="offcanvasNavbar">

                <div className="offcanvas-header">
                  <button type="button" className="btn-close text-reset ms-auto" data-bs-dismiss="offcanvas" aria-label="Close" />
                </div>

                <div className="offcanvas-body p-0">

                  <div className="card w-100">

                    <div className="card-body">

                      <ul className="nav nav-tabs nav-pills nav-pills-soft flex-column fw-bold gap-2 border-0">
                        
                    
                        <li className="nav-item" data-bs-dismiss="offcanvas">
                          <a className={this.state.tab !== "card" ?"nav-link d-flex mb-0 active ":"nav-link d-flex mb-0 t"} href="#nav-setting-tab-1" data-bs-toggle="tab"><span>Cuenta </span></a>
                        </li>
                       
                
                        <li className="nav-item" data-bs-dismiss="offcanvas">
                          <a className="nav-link d-flex mb-0" href="#nav-setting-tab-3" data-bs-toggle="tab"> <span>Pacientes </span></a>
                        </li>
                        <li className="nav-item" data-bs-dismiss="offcanvas">
                          <a href="#nav-setting-tab-4" data-bs-toggle="tab" className={this.state.tab === "card" ?"nav-link d-flex mb-0 active":"nav-link d-flex mb-0 "}><span>Sesiones </span></a>
                        </li>
                      
                    
                      </ul>

                    </div>


                    <div className="card-footer text-center py-2">
                      <a className="btn btn-link text-danger btn-sm" onClick={() => logout()}>Cerrar sesión</a>
                    </div>
                  </div>

                </div>

              </div>
            </nav>
          </div>
            {/* Main content START */}
            <div className="col-lg-6   vstack ">
            {/* Setting Tab content START */}
            <div className="tab-content py-0 mb-0  p-0  m-0 mt-0 ">
              {/* Account setting tab START */}                 
         
              <div  className={this.state.tab !== "card" ?"tab-pane show active fade":"tab-pane fade"}  id="nav-setting-tab-1">
                {/* Account settings START */}
              
                <div className="card mb-4">
                  {/* Title START */}
                  <div className="card-header border-0 pb-0">
                    <h1 className="h5 card-title">Editar perfil publico</h1>
                    <p className="mb-0">Añade toda tu información</p>
                  </div>
                  {/* Card header START */}
                  {/* Card body START */}
                  <div className="card-body">
                  <div className="">
                    <div className="rounded-top"  />
                    {/* Card body START */}

                      <div className="card card-body  shadow   pb-0  mt-0">
                              <div className="d-flex d-md-block d-lg-flex align-items-start pt-lg-2 mb-4 mt-2">
                                
                              <div className="avatar avatar-xl mt-2 mb-3 ">
                                  <a>
                                  <img className="avatar-img rounded-circle border border-white border-3 bg-dark" src={this.state.img} alt="" />
                                  </a>
                                
                                  <SelectImage ComponentRender={() => <>
                                    <a className="icon-md btn btn-primary rounded-circle iconProfile rounded circle" >
                                      <i className="bi bi-camera fs-6"> </i>
                                    </a>
                                  </>} onDone={(newItem) => this.setState({img:newItem})}/>
                                  
                                </div>
                                <div className="mt-2 mx-4">
                                  <h3 className=" mb-0 ">{this.state.name || auth.currentUser?.displayName} </h3>
                                  <ul className="list-unstyled fs-sm mt-3 mb-0">
                                    <li><a className="nav-link fw-normal p-0"><i className="fas-calendar opacity-60 " />Usuario desde {auth.currentUser?.metadata?.creationTime.slice(0,16)}</a></li>
                                    <li><a className="nav-link fw-normal p-0"><i className="fas-mail opacity-60 " />{auth.currentUser?.email}</a></li>
                                    <li><a className="nav-link fw-normal p-0"><i className="fas-mail opacity-60 display-linebreak " />{this.state.description}</a></li>

                                  </ul>
                                  
                            
                                  </div>
                              </div>


                          <br/>

                      


                      </div>
                    </div>


                    {/* Form settings START */}
                    <div className="row g-3 mt-2">
                      {/* First name */}
                      <div className="col-sm-6 col-lg-6">
                        <label className="form-label">Nombre</label>
                        <input   value={this.state.name} onChange={(e) => this.setState({name:e.target.value})} type="text" className="form-control"    />
                      </div>
                    
                 
                   

                      <div className="col-12">
                        <label className="form-label">Descripción perfil publico</label>
                        <textarea  className="form-control" value={this.state.description} onChange={(e) => this.setState({description:e.target.value})} rows={4} />
                        <small>Character limit: 300</small>
                      </div>
                      {/* Button  */}
                      <div className="col-12 text-end">
                        <button  className="btn btn-sm btn-primary mb-0" onClick={() => this.updateUser()}>Save</button>
                      </div>
                    </div>
                    {/* Settings END */}
                  </div>
                  {/* Card body END */}
                </div>
                {/* Account settings END */}
                {/* Change your password START */}
            
                {/* Card END */}
              </div>
              <div  className={this.state.tab === "card" ?"tab-pane show active fade":"tab-pane fade"}   id="nav-setting-tab-4">
                {/* Card START */}
                
                <div className="card">
                  {/* Card header START */}
                  <div className="card-header border-0 pb-0 ">
                    <div className="row">
                    <div className="col-md-6 col-12 col-md-6 mb-3">
                      <h5 className="card-title">Tus sesiones</h5>
                      <p className="mb-0">Tus siguiente sesiones, en el caso de no poder asistir a una cancela la sesión y notifica a tu paciente</p>
                    </div>

                    </div>
                  
                 
                  </div>
                  <div className="h-200">
                  {this.renderSesions()}

                  </div>
                  <br/>
                  <br/>
                  <br/>
                  <br/>
                  <br/>

                  {/* Card body END */}
                </div>
                {/* Card END */}
                
              </div>

    

              <div className="tab-pane fade" id="nav-setting-tab-3">
                {/* Card START */}
                
                <div className="card">
                  {/* Card header START */}
                  <div className="card-header border-0 pb-0 ">
                    <div className="row">
                    <div className="col-12 col-md-6">
                      <h5 className="card-title">Tus pacientes</h5>
                      <p className="mb-3  ">Aquí puedes ver tus pacientes asignados en Theraphya</p>
                  
                    </div>

                    </div>
                  
                 
                  </div>
                  </div>

                  <div className="card mt-3">
                  {/* Card header START */}
               
                  <div className="card-body">
                            {this.renderPatients()}
                  </div>
                  
                  </div>
                {/* Card END */}
              </div>
         
          
            </div>
            {/* Setting Tab content END */}
          </div>
          {/* Sidenav START */}
   
          {/* Sidenav END */}
        
        </div> {/* Row END */}
      </div>
      {/* Container END */}
    </main>

        </>
      )
    }
  }

  render() {
      return this.renderProfile()
    }
} 
  


export default Settings
