import React , { useState} from "react";
import { auth, db } from "../firebase";

import {
  CardElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';

import toast from 'react-hot-toast';

const BASE_URL = 'https://us-central1-theraphya-com.cloudfunctions.net/app'


function AddCard({addMethod}) {
    
  
    const stripe = useStripe();
    const elements = useElements();

    const [address, setAddress] = useState("");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");

  

    async function submit() {
      toast.promise(
        handleSubmit() ,
         {
           loading: 'Guardando tarjeta...',
           success: <b>Tarjeta guardada!</b>,
           error: <b>Ha ocurrido un problema.</b>,
         }
       );
    }

    async function  handleSubmit() {
        var emailSend = auth.currentUser.email || email;
        console.log(elements.getElement(CardElement))
        var card =elements.getElement(CardElement);
        // requestAnimationFrame( async  ()   => {
          fetch(`${BASE_URL}/create-customer`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              email:emailSend,
            }),
          }).then(r => r.json()).then((e) => {
            console.log(e.customer.id)
             stripe.createPaymentMethod({
                    type: 'card',
                    card: card,
                    billing_details: {
                    name: name,
                    email: emailSend,
                    address: address,
                  },
                }).then(({paymentMethod}) => {
                  console.log(paymentMethod)

                  if (paymentMethod) {
                    const paymentMethodId = paymentMethod.id;
                     console.log(paymentMethod.id + " <== method && ==> " + e.customer.id)
                          // IF created user
                    fetch(`${BASE_URL}/attach-payment`, {
                      method: 'POST',
                      headers: {
                        'Content-Type': 'application/json',
                      },
                      body: JSON.stringify({
                        email:emailSend,
                        customer: e.customer,
                        paymentMethodId:paymentMethodId
                      }),
                    })
                    addMethod()
                    db.collection("users").doc(emailSend).update({paymentMethodId, paymentMethod, customer: e.customer.id})
                    // Send the ID of the PaymentMethod to your server for the next step
                  } else {
                    
                  }


                })
              
          });
         
        // });
      }

    
  
    const cardStyle = {
        style: {
          base: {
            color: "#000",
            fontFamily: 'Arial, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
              color: "#000"
            }
          },
          invalid: {
            fontFamily: 'Arial, sans-serif',
            color: "#fa755a",
            iconColor: "#fa755a"
          }
        }
      };
    return (
        <>
        <div className="col-12 mt-2">
        { !auth.currentUser ?
          <>
          <label className="form-label fw-bold text-dark">Email  </label>
          <input   value={email} onChange={(e) => setEmail(e.target.value)} type="text" className="form-control form-control-lg"    />
          <br/>
          </>
        : 
        <>
        <label className="form-label fw-bold text-dark">Email </label>
        <input   value={auth.currentUser.email} type="disable" disabled={true} className="form-control form-control-lg disable"    />
        <br/>
        </>
        }
        </div>

        <CardElement  id="card-element"  options={cardStyle} className="form-control text-dark form-control-lg" />
      
        <div className="col-sm-12 col-lg-12 mt-3">
          <label className="form-label fw-bold text-dark">Nombre en tarjeta </label>
          <input   value={name} onChange={(e) => setName(e.target.value)} type="text" className="form-control form-control-lg"    />
          <br/>
          <label className="form-label fw-bold text-dark">Dirección de facturación</label>
          <input   value={address} onChange={(e) => setAddress(e.target.value)} type="text" className="form-control form-control-lg"    />
          <br/>                       
        </div>
        
        <button
        onClick={submit} 
        className="mt-3 btn btn-primary"
        >
            <span id="button-tex fw-bold">
            Añadir tarjeta
            </span>
        </button>
        </>
    )
   }
  
  export default AddCard;