import React from "react";


import LogoWhite from "../assets/logo-small.png"

import Compromiso from "../assets/icons/compromiso.png"
import Profesionales from "../assets/icons/profesionales.png"
import TerapiaPersonalizada from "../assets/icons/terapia-personalizada.png"

import Estigmas from "../assets/icons/estigmas.png"
import Igualdad from "../assets/icons/igualdad.png"
import Crisis from "../assets/icons/crisis.png"


import { Link  } from "react-router-dom";

class Landing extends React.Component {

  constructor(props) {
    super(props) 
    this.state = {
      loading:true,
    }
  } 



  render() {

      return (
		    <div>
			<section className="pb-0">
		    	<div className="row mb-3 mb-xl-0 bg "
		    	style={{
		    	height:'130px',
		    	// backgroundImage: 'url(https://images.unsplash.com/photo-1551847677-dc82d764e1eb?q=80&w=1740&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)', 
		    	backgroundPosition: 'center left', backgroundSize: 'cover', backgroundOpacity:0.2}}
		    	>
		    	<div className=" container" >
					<div className="container text-center">
						<h1 className="lh-base mb-0 mt-5 text-dark italic ">SOBRE NOSOTROS</h1>
					</div>
				</div>
				</div>
			<div className="container">
				{/* Main title */}
			

				{/* About detail START */}
				<div className="row">
					{/* Content */}
					<div className="col-lg-6 pe-lg-5 mt-5">
						<h2>
							<img src={Compromiso} width="50px" />
					 	</h2>
						<h3>Nuestro compromiso </h3>
						<p className="mb-5">Nuestro objetivo es simple pero poderoso: queremos que todas las personas encuentren la felicidad y el bienestar, independientemente de su género, raza, posición económica o social</p>

					</div>

					<div className="col-lg-6 pe-3  mt-5 ">
						<h2>
							<img src={Compromiso} width="50px" />
					 	</h2>
						<h3>Comodidad y Facilidad </h3>
						<p className="mb-5">Queremos que tu experiencia sea lo más cómoda y fácil posible. Accede a nuestras sesiones desde la comodidad de tu hogar, adaptando la terapia a tu estilo de vida	</p>

					</div>


					<div className="col-lg-6 pe-3	mt-2">
						<h2>
							<img src={Profesionales} width="50px" />
					 	</h2>
						<h3>Excelencia Profesional </h3>
						<p className="mb-5">Contamos con los mejores psicólogos, herramientas digitales, y contenido educativo, dedicados a brindarte un apoyo profesional y empático. Nuestro equipo está aquí para guiarte en tu camino hacia el bienestar</p>

					</div>


					<div className="col-lg-6 pe-3	mt-2">
						<h2>
							<img src={TerapiaPersonalizada} width="50px" />
					 	</h2>
						<h3>Terapia Personalizada </h3>
						<p className="mb-5">En Theraphya, reconocemos que cada individuo es único. Ofrecemos servicios de terapia individualizados, adaptados a tus necesidades y circunstancias personales</p>

					</div>


					{/* Image */}
					<div className="col-lg-5 mt-7 mt-lg-0">
						<div className="position-relative d-flex justify-content-center">
							{/* Hero image */}


							{/* Trustpilot review START */}

						</div>
					</div>
				</div>
				{/* About detail END */}
			</div>	
		</section>

		<section className="pb-0">
			<div className="container">
				{/* Title */}
				<div className="inner-container text-center mb-4 mb-sm-6">
					<h2 className="mb-0">NUESTRO IMPACTO SOCIAL</h2>
				</div>

				{/* Service list START */}
				<div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 g-4">


					{/* Service item */}
					<div className="col">
						<div className="card card-hover-shadow border  p-4">
							{/* Card body */}
							<div className="card-body p-0">
								{/* Icon */}
								<img src={Estigmas} width="60px" className="mt-2 mb-3" />
			
								{/* Content */}
								<h5 className="mb-3"><a href="#">Eliminar Estigmas</a></h5>
								<p>Desafiamos y trabajamos para eliminar los estigmas asociados con la salud mental. Creemos en un espacio donde cada individuo se sienta libre de buscar apoyo sin miedo al juicio
									<br/>
									<br/>
									<br/>
									
								</p>
							</div>

						
						</div>
					</div>

					{/* Service item */}
					<div className="col">
						<div className="card card-hover-shadow border  p-4">
							{/* Card body */}
							<div className="card-body p-0">
								{/* Icon */}
								<img src={Igualdad} width="60px" className="mt-2 mb-3" />
								{/* Content */}
								<h5 className="mb-3"><a href="#">Bienestar e igualdad</a></h5>
								<p>Nos esforzamos por brindar acceso universal al bienestar emocional. A través de nuestras iniciativas, trabajamos para hacer que la terapia online sea accesible para todos, independientemente de su ubicación o situación económica</p>
							</div>

							{/* Card footer */}
						
						</div>
					</div>

					{/* Service item */}
					<div className="col">
						<div className="card card-hover-shadow border  p-4">
							{/* Card body */}
							<div className="card-body p-0">
								{/* Icon */}
								<img src={Crisis} width="60px" className="mt-2 mb-3" />
			
								{/* Content */}
								<h5 className="mb-3"><a href="#">Fuente de Apoyo en Crisis</a></h5>
								<p>En momentos de crisis, estamos aquí para ofrecer apoyo. Nuestra plataforma sirve como un refugio seguro donde puedes encontrar ayuda profesional cuando más lo necesitas
									<br/>
									<br/>
									<br/>

								</p>
							</div>

						
						</div>
					</div>
				</div>
				{/* Service list END */}
			</div>	
		</section>

		

		<section className="pt-0 pt-lg-2 mb-5">
            <div className="container position-relative">
              {/* SVG decoration START */}
              <figure className="position-absolute top-50 start-50 translate-middle ms-2">
                <svg>
                  <path
                    className="fill-white opacity-4"
                    d="m496 22.999c0 10.493-8.506 18.999-18.999 18.999s-19-8.506-19-18.999 8.507-18.999 19-18.999 18.999 8.506 18.999 18.999z"
                  />
                  <path
                    className="fill-white opacity-4"
                    d="m775 102.5c0 5.799-4.701 10.5-10.5 10.5-5.798 0-10.499-4.701-10.499-10.5 0-5.798 4.701-10.499 10.499-10.499 5.799 0 10.5 4.701 10.5 10.499z"
                  />
                  <path
                    className="fill-white opacity-4"
                    d="m192 102c0 6.626-5.373 11.999-12 11.999s-11.999-5.373-11.999-11.999c0-6.628 5.372-12 11.999-12s12 5.372 12 12z"
                  />
                  <path
                    className="fill-white opacity-4"
                    d="m20.499 10.25c0 5.66-4.589 10.249-10.25 10.249-5.66 0-10.249-4.589-10.249-10.249-0-5.661 4.589-10.25 10.249-10.25 5.661-0 10.25 4.589 10.25 10.25z"
                  />
                </svg>
              </figure>
              {/* SVG decoration END */}
              <div className="row mt-5">
                <div className="col-12">
                  <div className="bg-info p-4 p-sm-5 rounded-3">
                    <div className="row position-relative">
                      {/* Svg decoration */}
                      <figure className="fill-white opacity-1 position-absolute top-50 start-0 translate-middle-y">
                        <svg width="141px" height="141px">
                          <path d="M140.520,70.258 C140.520,109.064 109.062,140.519 70.258,140.519 C31.454,140.519 -0.004,109.064 -0.004,70.258 C-0.004,31.455 31.454,-0.003 70.258,-0.003 C109.062,-0.003 140.520,31.455 140.520,70.258 Z" />
                        </svg>
                      </figure>
                      {/* Action box */}
                      <div className="col-11 mx-auto position-relative">
                        <div className="row align-items-center">
                          {/* Title */}
                          <div className="col-lg-7">
						  <div className="icon-lg round bg-opacity-1 rounded-circle mb-3">
                          <img src={LogoWhite} className="rounded-circle rounded"  width="40px" />
                      </div>
                            <h3 className="text-white">Te lo ponemos fácil</h3>
                            <p className="text-white mb-3 mb-lg-0">
							Suscríbete a <b>Theraphya</b> para tener el mejor precio para tus sesiones
                            <br/>

							El mejor precio para ti
                            </p>
                          </div>
                          {/* Content and input */}
                          <div className="col-lg-5 text-lg-end">
                            <Link to="/precios" className="btn btn-secondary mb-0">
                              Ver precios 
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

		</div>

      
      );

    }
  }


export default Landing;
