export default function Preguntas() {
    return (
    <section>
        <div className="container align-items-center ">
              <h2 className="fs-1 mb-2 text-center ">Preguntas frecuentes</h2>
              <p className="mb-4 text-center ">
              Accede a nuestro catálogo de expertos para iniciar tu camino de mejora personal
              </p>
            <div className="d-flex justify-content-center align-items-center">
            <div className="accordion col-md-10   text-left" id="accordionExample">

            <div className="accordion-item shadow m-4 text-left">
              <h2 className="accordion-header" id="headingOne">
                <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">¿Qué es Theraphya y cómo funciona? </button>
              </h2>
              <div className="accordion-collapse collapse show text-left" aria-labelledby="headingOne" data-bs-parent="#accordionExample" id="collapseOne">
                <div className="accordion-body text-dark text-left ">Theraphya es tu hogar digital para el bienestar emocional. Conectamos a individuos con terapeutas expertos a través de sesiones de terapia online. Simplemente regístrate, elige a tu terapeuta y comienza tu viaje hacia el bienestar. </div>
              </div>
            </div>


            <div className="accordion-item shadow m-4">
              <h2 className="accordion-header" id="headingTwo">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">¿Cómo sé si la terapia online es adecuada para mí? </button>
              </h2>
              <div className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample" id="collapseTwo">
                <div className="accordion-body text-dark ">La terapia online es una opción conveniente para muchas personas. Si buscas comodidad, flexibilidad y privacidad, Theraphya puede ser la elección ideal. Habla con nuestro equipo para obtener más información y descubrir si es adecuado para ti. </div>
              </div>
            </div>


            <div className="accordion-item shadow m-4 border-rounded">
              <h2 className="accordion-header" id="headingThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                ¿Qué hace única a la terapia en Theraphya? </button>
              </h2>
              <div className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample" id="collapseThree">
                <div className="accordion-body text-dark ">
En Theraphya, ofrecemos un servicio personalizado, cómodo y accesible. Nuestro enfoque inclusivo garantiza que todos, independientemente de su género, raza o posición social, puedan buscar felicidad y bienestar. 
                </div>
              </div>
            </div>



            <div className="accordion-item shadow m-4 border-rounded">
              <h2 className="accordion-header" id="headingThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse4" aria-expanded="false" aria-controls="collapse4">
                ¿Cómo elijo a mi terapeuta? </button>
              </h2>
              <div className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample" id="collapse4">
                <div className="accordion-body text-dark  text-left">
Puedes explorar perfiles de terapeutas en nuestra plataforma y elegir aquel que mejor se adapte a tus necesidades y preferencias. También ofrecemos asesoramiento personalizado para ayudarte a tomar la decisión correcta. 
                </div>
              </div>
            </div>


            <div className="accordion-item shadow m-4 border-rounded">
              <h2 className="accordion-header" id="headingThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse5" aria-expanded="false" aria-controls="collapseThree">
                ¿Cómo se protege mi privacidad?  </button>
              </h2>
              <div className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#collapse5" id="collapse5">
                <div className="accordion-body text-dark ">
Tu privacidad es nuestra prioridad. Utilizamos tecnologías seguras y procesos de cifrado para proteger tu información. Todas las sesiones son confidenciales y cumplimos con los estándares éticos más altos. 
                </div>
              </div>
            </div>



            <div className="accordion-item shadow m-4 border-rounded">
              <h2 className="accordion-header" id="headingThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse6" aria-expanded="false" aria-controls="collapseThree">
               ¿Puedo acceder a Theraphya desde cualquier lugar?  </button>
              </h2>
              <div className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#collapse6" id="collapse6">
                <div className="accordion-body text-dark ">
Sí, puedes acceder a Theraphya desde cualquier lugar con conexión a internet. Nuestra plataforma está diseñada para brindarte flexibilidad y comodidad. 
                </div>
              </div>
            </div>



            <div className="accordion-item shadow m-4 border-rounded">
              <h2 className="accordion-header" id="headingThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse7" aria-expanded="false" aria-controls="collapseThree">
                ¿Qué recursos adicionales ofrece Theraphya?  </button>
              </h2>
              <div className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#collapse7" id="collapse7">
                <div className="accordion-body text-dark ">
                Además de sesiones de terapia, ofrecemos una variedad de recursos diseñados con esmero, como herramientas interactivas y contenido educativo, para complementar tu experiencia y apoyar tu crecimiento personal. 


                </div>
              </div>
            </div>



            <div className="accordion-item shadow m-4 border-rounded">
              <h2 className="accordion-header" id="headingThree">
                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse8" aria-expanded="false" aria-controls="collapseThree">
               ¿Cómo puedo comenzar mi viaje con Theraphya?  </button>
              </h2>
              <div className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#collapse8" id="collapse8">
                <div className="accordion-body text-dark ">
        Regístrate en nuestra plataforma, elige a tu terapeuta, y comienza tu viaje hacia una vida más plena y feliz. Estamos aquí para apoyarte en cada paso del camino. 

    
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className=" text-center">
        <a href="mailto:info@theraphya.com">¿Necesitas ayuda? <span className=" cursor text-decoration-underline">Contáctanos aquí </span></a>
        </div>

        </div>
        </section>)
} 