import React from "react";

import {  auth, db } from "../firebase";

import { animateScroll } from "react-scroll";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";

class Chat extends React.Component {

  constructor(props) {
    super(props) 
    this.state = {
      chats:[],
      messages:[],
      lastStatus:0,
      message:"",
      id:"",
      chatUser: {
        uid: "",
        lastStatus: 0,
        name:""
    },
    user: {},
  }

  } 

  scrollToBottom() {
    animateScroll.scrollToBottom({
      containerId: "chatBox"
    });
}

  componentDidMount() {
    this.getMessages(this.props.id);
  }


  async handleSend() {
    const text = this.state.text;
    this.setState({text:""})

    if(text !== "" && this.state.text !== undefined ){


    await db
      .collection('chats')
      .doc(this.state.id)
      .collection('messages')
      .add({
        text,
        createdAt: new Date().getTime(),
        user: {
          _id: auth.currentUser.email,
          email: auth.currentUser.email,
          avatar:  "https://static.vecteezy.com/system/resources/thumbnails/009/734/564/small/default-avatar-profile-icon-of-social-media-user-vector.jpg",
        },

      });

    await db
      .collection('chats')
      .doc(this.state.id)
      .set(
        {
          latestMessage: {
            text,
            createdAt: new Date().getTime()
          }
        },
        { merge: true }
      );
      
      this.getMessages(this.state.id)
      // sendNotification(userData.token, text)
      var latestMessage =  {text, date:Date.now()}

      if(this.props.therapist === true) {
        db.collection('users').doc(this.state.chatUser?.email).collection('chats').doc(this.state.id).set({check:false, latestMessage, datecheck: Date.now()},{merge: true})
        db.collection('therapists').doc(auth.currentUser.email).collection('patients').doc(this.state.chatUser?.email).set({check:true, latestMessage, datecheck: Date.now()},{merge: true})
    
      } else {
        db.collection('therapists').doc(this.state.chatUser?.email).collection('patients').doc(auth.currentUser.email).set({check:false, latestMessage, datecheck: Date.now()},{merge: true})
        db.collection('users').doc(auth.currentUser.email).collection('chats').doc(this.state.id).set({check:true, latestMessage, datecheck: Date.now()},{merge: true})
    
      } 
     
      
      this.setState({img:null,video:null})
      this.scrollToBottom()

   
      }
  }
 
  
  async getMessages(id) {

    this.setState({chatUser:this.props.chatUser})
    this.setState({id:id})
    await db.collection('chats').doc(id).collection("messages").orderBy("createdAt").onSnapshot((snapshot) => {
      const postData = [];
      snapshot.forEach((doc) => postData.push({ ...doc.data(), id: doc.id }));
      this.setState({messages:postData})
      this.scrollToBottom()
    });
  }



  renderMessages() {
    return (
      <>
      {this.state.messages?.map(data => (
          this.renderMessage(data)
          
      ))}

      </>
    )
   
  }
  




  renderMessage(data) {
    if(data?.user._id !== auth.currentUser?.email) {
      return (
        <div className="d-flex   border-1 mb-2 " key={data.createdAt}>
          <div className="flex-shrink-0 avatar avatar-xs me-2">
            <img className="avatar-img rounded-circle" src={data?.user?.avatar || "https://static.vecteezy.com/system/resources/thumbnails/009/734/564/small/default-avatar-profile-icon-of-social-media-user-vector.jpg"} alt="" />
          </div>
          <div className=" w-50">
            <div className="  bg-primary rounded">
              <div className="p-2 text-white" >
              {data?.text}
              <br/>
                <small className="small my-2 ">{ new Date(data.createdAt).toLocaleString() }</small>
              </div>
            </div>
          </div>
        </div>

      )
    } else {
      return(
      <div className="d-flex flex-column align-items-end mb-2 ">
             <div className=" w-50 ">
            <div className="  bg-terciary rounded border">
              <div className="p-2 text-dark" >
              {data?.text}
              <br/>
                <small className="small my-2 text-right">{ new Date(data.createdAt).toLocaleString() }</small>
              </div>
            </div>
          </div>
    </div>
      )
    }
  }


  render() {
      return (
      <>

        <Modal show={this.props.show}  className="center" fullscreen={"sm-down"}  id="modalCentered"  size={"lg"} centered>
        <ModalHeader className="modal-header"  >
        <div className="d-flex mb-2 mb-sm-0">
                <div className="flex-shrink-0 avatar me-2">
                <img className="avatar-img rounded-circle" src={this.props.chatUser?.img || "https://static.vecteezy.com/system/resources/thumbnails/009/734/564/small/default-avatar-profile-icon-of-social-media-user-vector.jpg"} alt="" />
                </div>
                <div className="d-block flex-grow-1">
                <h4 className="mb-0 mt-2">{this.props.chatUser?.name || this.props.chatUser?.email}</h4>

                </div>
            </div>
          <button className="btn-close" type="button" onClick={this.props.close} />
        </ModalHeader>
          <ModalBody className="chatBox p-2"  id="chatBox" >

            {this.renderMessages()}
            <p className="text-center fs-sm mb-1">Escribe con tu terapeuta...</p>
            {/* Chat conversation END */}
            <div ref={el => { this.el = el; }} />
            {/* Chat conversation END */}
          </ModalBody> {/*    Row END */}
          <ModalFooter className="w-100 h-10">
                  <div className="d-flex align-items-center justify-content-center col-12">
                    <textarea value={this.state.text} onChange={(e) => this.setState({text:e.target.value})} className="form-control " data-autoresize placeholder="Escribe un mensaje..." rows={1} defaultValue={""} />
                     <button onClick={() => this.handleSend()} className="btn  btn-primary ms-2"><i className="fa-solid fa-paper-plane fs-6" /></button>
                  </div>
            </ModalFooter>
        </Modal>
        {/* Container END */}
    </>
      );

    }
  }


export default Chat;
