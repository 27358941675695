
import Insta1 from "../assets/insta/1.png"
import Insta2 from "../assets/insta/3.png"
import Insta3 from "../assets/insta/2.png"



export default function InstaFeed() {
	return (
	  <div className="container mt-5">

	      <h2 className="text-center"><i className="fa-brands fa-instagram" /> Síguenos en Instagram </h2>

	      <div className="d-flex mt-5 mb-5">

	        <a  href="https://instagram.com/theraphya.app"  target="__blank"  className="col-4">
	          <img className="img-fluid " src={Insta1} />
	        </a>
	         <a href="https://instagram.com/theraphya.app"  target="__blank"  className="col-4">
	          <img className="img-fluid " src={Insta2} />
	        </a>
	          <a href="https://instagram.com/theraphya.app"  target="__blank"  className="col-4">
	          <img className="img-fluid " src={Insta3} />
	        </a >



	      </div> 

	      <a href="https://instagram.com/theraphya.app"  target="__blank" >
	      <h5 href="https://instagram.com/theraphya.app" className=" cursor text-primary text-center mb-5">@theraphya.app</h5>
	      </a>
	      </div>
	)
}