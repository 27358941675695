import React from "react";

import LogoWhite from "../assets/logo-white.png"
import { Link  } from "react-router-dom";
import AddCard from "./AddCard";
import { auth, db } from "../firebase";
import { Button } from "react-bootstrap";

import toast from 'react-hot-toast';
// import ConfettiExplosion from 'react-confetti-explosion';
const BASE_URL = 'https://us-central1-theraphya-com.cloudfunctions.net/app';



const images =  {
    amx:"../assets/images/cards/amx.png",
    visa: "../assets/images/cards/visa.png",
    mastercard: "../assets/images/cards/mastercard.png",

  }

class Pago extends React.Component {

  constructor(props) {
    super(props) 
    this.state = {
      loading:true,
      paymentMethod:null,
    }
  } 
  componentDidMount() {
      setTimeout(() => {
        this.getUser()
      }, 1000);
  }

  getUser() {
    if(auth.currentUser) {
        db.collection('users').doc(auth.currentUser.email).onSnapshot((doc) => {
        const userData = doc.data();
        this.setState(userData);
    })
    } else {
        window.location.href="/register"
    }
  }

  borrarMetodo() {
    this.setState({paymentMethod:null})
  }

  doPayment = async () => {
    if( this.state.paymentMethod ) {
      this.setState({loadingPay:true})
      var priceLook;
  
        if(this.state.priceSelected === "m1") {
          priceLook = "price_1Oc6uQGs4sg3xpWo8TN9f4hL"
        } 
        else {
          priceLook = "price_1Oc6uQGs4sg3xpWo8TN9f4hL"
        }
     
  
      fetch(`${BASE_URL}/create-subscription`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          priceLookupKey:priceLook,
          paymentMethod: this.state.paymentMethod?.id,
          customer: this.state.customer,
        }),
      }).then(r => r.json()).then(e => {
  
  
        // console.log(e)
  
  
        if(e.subcription === null || e.subscription?.latest_invoice.payment_intent?.last_payment_error !== null) {
          this.setState({modal: false, loadingPay: false})
  
          toast.error("There was a problem subscribing to this profile")
        }
        else if(e.subscription?.latest_invoice?.payment_intent?.status === "succeeded") {
          this.setState({modal: false, loadingPay: false, isExploding: true,paymentDone:true})

          toast.success(`Pago realizado correctamente`)
          setTimeout(() => {
            this.setState({isExploding:false})
          }, 1000);

        }
        else {
          if((e.subscription?.latest_invoice?.payment_intent?.next_action?.type === "use_stripe_sdk")){
            // payWithSDK(e)
            console.log("Pay with SDK needed")
          }
        }
        
  
      })
    
    
    } else {
      this.setState({modal: false, loading: false})
      toast.error("There was a problem subscribing ")
    }
    
    }
  
  render() {
      return (
        <>
        {/* Header END */}
        <main>
			{/* section*/}
			<div className="mt-4">
				<div className="container">
					{/* row */}
					<div className="row">
						{/* col */}
						<div className="col-12">
							{/* breadcrumb */}
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb mb-0">
									<li className="breadcrumb-item"><a >Inicio</a></li>
									<li className="breadcrumb-item active"><a> Pago</a></li>
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div>
			{/* section */}
			<section className=" mb-5 mt-8">
				<div className="container">
					{/* row */}
					<div className="row">
						{/* col */}
						<div className="col-12">
							<div>
								<div className="mb-8">
									{/* text */}
									<h1 className="fw-bold mb-2">Suscríbete a theraphya</h1>
								
								</div>
							</div>
						</div>
					</div>
					<div>
						{/* row */}
						<div className="row">
							<div className="col-xl-7 col-lg-7 col-md-12">
								{/* accordion */}
								<div className="accordion accordion-flush" id="accordionFlushExample">
									{/* accordion item */}
                                    <div className="">
                                    <div className="  ">
                                        { this.state.paymentMethod ? 
                                            <>
                                            <div className="hstack gap-2 mb-3 bg-white cardDebit card shadow pt-2 mt-3  " >
                                            {/* Avatar */}
                                            <div className="avatar avatar d-flex justify-content-center align-items-center ml-3">
                                            { this.state.paymentMethod.card ?  <img className="img-fluid pr-3"  src={images[this.state.paymentMethod.card.brand]} alt="" /> : null }
                                            </div>
                                            {/* Title */}
                                            <div/>
                                            <div className="overflow-hidden ml-3 ">
                                            <a className="h6 mb-0 text-dark"> •••• •••• •••• {this.state.paymentMethod?.card?.last4} </a>
                                            <p className="mb-0  text-truncate">{this.state.paymentMethod?.card?.exp_month}/{this.state.paymentMethod?.card?.exp_year} </p>

                                            </div>
                                            {/* Button */}
                                            <a onClick={() => this.borrarMetodo()} className=" ms-auto mx-3" >
                                            { this.state.paymentMethod ?  <a>Cambiar tarjeta</a> : null}
                                        
                                            </a>
                                        </div>
                                        <p className="mb-4 mt-2">
                                        Para más infomación de pago revisa <Link to="/terms"> términos y condiciones</Link>
                                        </p>
                                        {/* {this.state.isExploding && <ConfettiExplosion />}    */}
                                        <Button onClick={() => this.doPayment()} type="primary" size="large">Pagar</Button>
                                   
                                            <br/>
                                            <br/>
                                            {this.state.paymentDone ? <Link className="link" to="/cuenta">Entra a tu cuenta de Theraphya y reserva tu siguiente sesión</Link> :null}
                                          { this.state.loadingPay ?
                                        <div className="text-center mb-4 mt-5">
                                        <div className="spinner-border text-primary" role="status">
                                            <h1 className="sr-only">Realizando pago...</h1>
                                        </div>
                                        </div>
                                        : null 
           }
                                        </> 
                                        : 
                                        <AddCard addMethod={() => this.getUser()}/>
                                        }


	                                    </div>
                                        </div>
                                   
								</div>
							</div>

							<div className="col-md-12 col-xl-5 col-lg-5">
                            <div className="card      p-0">
                    {/* Badge */}


                      {/* Card header */}
                      <div className="card-header bg-light bg-opacity-10  shadow border-opacity-25 p-4">
                        {/* Icon */}
                        <div className="icon-lg bg-primary bg-opacity-1 rounded-circle mb-3">
                            <img src={LogoWhite}  width="40px" />
                        </div>
                        {/* Title and price */}
                        <h6 className="mb-3">Suscripción mensual</h6>
                        <p className="mb-0"> <span className="h2 mb-0 plan-price" data-monthly-price="$59" data-annual-price="$45">38€</span> /sesión</p>
                        <small>Pago mensual de 4 sesiones</small>
                      </div>

                      {/* Card body */}
                  
                    </div>
							</div>
						</div>
					</div>


                <div className="card  bg-light bg-opacity-10 p-4 col-7 mt-2">
                        <div className="text-center text-lg-start">
                          <h6 className="heading-color">Características suscripción</h6>
                          {/* List */}
                          <ul className="list-group list-group-borderless mb-2 mb-sm-4">
                            <li className="list-group-item d-flex heading-color mb-0"><i className="bi bi-check-lg heading-color me-1"></i>1x Sesion semanal</li>
                            <li className="list-group-item d-flex heading-color mb-0"><i className="bi bi-check-lg heading-color me-1"></i>Se te asigna un terapeuta para ti</li>
                            <li className="list-group-item d-flex heading-color mb-0"><i className="bi bi-check-lg heading-color me-1"></i>Contacto directo con tu terapeuta</li>
                            <li className="list-group-item d-flex heading-color mb-0"><i className="bi bi-check-lg heading-color me-1"></i>Acceso a todos los terapeutas disponibles</li>
                            <li className="list-group-item d-flex heading-color mb-0"><i className="bi bi-check-lg heading-color me-1"></i>Una sesión semanal </li>
                            <li className="list-group-item d-flex heading-color mb-0"><i className="bi bi-check-lg heading-color me-1"></i>35€ coste de sesiones extras      </li>
                            <li className="list-group-item d-flex heading-color mb-0"><i className="bi bi-check-lg heading-color me-1"></i>24/7 Soporte</li>
                          </ul>
                          {/* Button */}
                        </div>
                </div>  
				</div>



			</section>

            

       
            <br/>
            <br/>
    

            {/* <Preguntas/>     */}
		</main>

        {/* **************** MAIN CONTENT START **************** */}

      </>
      
      );

    }
  }


export default Pago;
